@charset "UTF-8";

@font-face {
  font-family: "BauPro";
  src: url("../../assets/fonts/BauPro.woff") format("woff2"), url("../../assets/fonts/BauPro.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BauPro";
  src: url("../../assets/fonts/BauPro-Medium.woff2") format("woff2"), url("../../assets/fonts/BauPro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

html {
  height: 100%;
}

.singleproject {
  font-family: "BauPro";
  font-weight: normal;
}

.singleproject > * + *,
.singleproject .projectmain > * + * {
  margin-top: var(--margin);
}

.singleproject > div {
  border-color: var(--color-lo);
  border-radius: unset;
  border-style: solid;
  border-width: calc(var(--margin) * 0.2);
  padding: calc(var(--margin) * 1);
}

.singleproject h2 {
  font-weight: 500;
  font-size: 1.6rem;
}

.singleproject h3 {
  font-weight: 500;
  font-size: 1.3rem;
}

.singleproject ul {
  list-style-position: inside;
  list-style-type: "\2013\0020";
}

.singleproject ol {
  list-style-position: outside;
  padding-left: var(--margin);
}

.singleproject blockquote {
  border-left-color: var(--color-fg);
  border-left-radius: unset;
  border-left-style: solid;
  border-left-width: 0.2rem;
  font-style: italic;
  max-width: 80%;
  padding-left: calc(var(--margin) * 0.5);
}

.singleproject blockquote:before {
  content: open-quote;
}

.singleproject blockquote:after {
  content: close-quote;
}

.singleproject code {
  background-color: var(--color-lo);
  font-family: "Lucida Console", Monaco, monospace;
  font-size: 0.8rem;
  padding: 0 calc(var(--margin) * 0.2);
}

.singleproject .code {
  background-color: var(--color-fg);
  color: var(--color-bg);
  font-family: "Lucida Console", Monaco, monospace;
  font-size: 0.8rem;
  padding: var(--margin);
}

.singleproject .code > * {
  white-space: pre-wrap;
}

.singleproject .bbb .bbblink {
  background: var(--color-fg);
  border-radius: var(--margin);
  color: var(--color-bg);
  cursor: pointer;
  display: inline-block;
  font-size: 0.8rem;
  font-weight: 500;
  padding: calc(var(--margin) * 0.5);
  text-align: center;
  width: 100%;
}

.singleproject .video {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}

.singleproject .video > iframe {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.singleproject .audio > audio {
  width: 100%;
}

/* TODO: is necessary? */
/*
.singleproject .audio {
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

.singleproject .audio audio {
  width: 100%;
}

.singleproject .audio .slider .gap-progress .pin {
  background-color: black;
}

.singleproject .audio .volume__button svg path,
.singleproject .audio .play-pause-btn svg path {
  fill: black !important;
}

.singleproject .audio .slider .gap-progress {
  background-color: #63bdff;
}
*/

.singleproject .tagbubble {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16ch, 1fr));
  grid-gap: calc(var(--margin) * 0.5);
}

.singleproject .tagbubble > * {
  background-color: var(--color-fg);
  color: var(--color-bg);
  border-radius: var(--margin);
  /*
  cursor: pointer;
  */
  font-size: 0.8rem;
  font-weight: 500;
  padding: calc(var(--margin) * 0.3) calc(var(--margin) * 0.6);
  text-align: center;
  /*
  white-space: nowrap;
  */
}

/*
.singleproject .tagbubble > *:not(:first-child):not(:only-child) {
  margin-left: calc(var(--margin) * 0.2);
}

.singleproject .tagbubble > *:not(:last-child):not(:only-child) {
  margin-right: calc(var(--margin) * 0.2);
}
*/

/* TODO: not yet created dynamically; needs testing */
.singleproject .info {
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
  width: 50%;
}
